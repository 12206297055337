import queryClient from '@api/queryClient'
import { useMutation } from 'react-query'
import authApi from './actions'
import { authKeys } from './constants'

export const useAuthMutations = () => {
  const login = useMutation(authApi.login, {
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })

  const logout = useMutation(authApi.logout, {
    onMutate: () => {},
    onSuccess: () => {},
    onSettled: async () => {
      queryClient.removeQueries(authKeys.root)
      await queryClient.resetQueries()
      localStorage.removeItem('companyId')
    },
  })

  return { login, logout }
}

export default useAuthMutations
