import { keyToUrl } from '@tools/common'

export const companiesKeys = {
  root: ['companies'] as const,
  company: (id: string) => [...companiesKeys.root, id] as const,
}

export const companiesUrls = {
  root: keyToUrl(companiesKeys.root),
  company: (id: string) => keyToUrl(companiesKeys.company(id)),
}
