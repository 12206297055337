import { useMutation, useQueryClient } from 'react-query'
import usersApi from './actions'
import { usersKeys } from './constants'

export const useUsersMutations = (companyId?: string) => {
  const queryClient = useQueryClient()

  const createUser = useMutation(usersApi.createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.root(companyId))
    },
  })

  const updateUser = useMutation(usersApi.updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.root(companyId))
    },
  })

  const deleteUser = useMutation(usersApi.deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.root(companyId))
    },
  })

  return { createUser, updateUser, deleteUser }
}

export default useUsersMutations
