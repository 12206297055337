import { useMutation, useQueryClient } from 'react-query'
import companyApi from './actions'
import { companiesKeys } from './constants'

const useCompaniesMutations = () => {
  const queryClient = useQueryClient()

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(companiesKeys.root)
    },
  }

  const createCompany = useMutation(companyApi.createCompany, mutationOptions)
  const updateCompany = useMutation(companyApi.updateCompany, mutationOptions)

  return { createCompany, updateCompany }
}

export default useCompaniesMutations
