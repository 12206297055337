import httpClient from '@api/httpClient'
import type { Depot } from '@models/depot'
import { depotsUrls } from './constants'

const getDepots = async (companyId: string) => {
  const { data } = await httpClient.get<Depot[]>(depotsUrls.root, {
    params: { companyId },
  })
  return data
}

const createDepot = async (props: Partial<Depot>) => {
  const { data } = await httpClient.post<Depot>(depotsUrls.root, props)
  return data
}

const updateDepot = async ({ id, ...props }: Partial<Depot>) => {
  const { data } = await httpClient.patch<Depot>(depotsUrls.depot(id!), props)
  return data
}

export default {
  getDepots,
  createDepot,
  updateDepot,
}
