import type { User } from '@models/user'
import { UserFilters } from '@models/user'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { getAuthCredentialsExist } from '../auth/tools'
import usersApi from './actions'
import { usersKeys } from './constants'

interface UseUsersData {
  users: User[]
  usersMap: Map<string, User>
}

export const selectData = (data: User[]): UseUsersData => ({
  users: data,
  usersMap: new Map(data.map(user => [user.id, user])),
})

export const useUsers = (filters: UserFilters, refetchCount?: () => void) => {
  const query = useQuery<User[], AxiosError, UseUsersData>(
    usersKeys.users(filters),
    () => usersApi.getUsers(filters),
    {
      enabled: getAuthCredentialsExist() && !!filters?.companyId,
      select: selectData,
      onSuccess: () => {
        if (refetchCount) {
          refetchCount()
        }
      },
    },
  )

  return query
}

export default useUsers
