import httpClient from '@api/httpClient'
import type { Company } from '@models/company'
import { companiesUrls } from './constants'

const getCompanies = async () => {
  const { data } = await httpClient.get<Company[]>(companiesUrls.root)
  return data.filter(company => company.companyExternalId !== 'Rinkai')
}

const createCompany = async (props: Partial<Company>) => {
  const { data } = await httpClient.post<Company>(companiesUrls.root, props)
  return data
}

const updateCompany = async ({ id, ...props }: Partial<Company>) => {
  const { data } = await httpClient.put<Company>(
    companiesUrls.company(id!),
    props,
  )
  return data
}

export default {
  getCompanies,
  createCompany,
  updateCompany,
}
