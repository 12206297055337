import httpClient from '@api/httpClient'
import type { User, UserFilters } from '@models/user'
import { usersUrls } from './constants'

const getUsers = async (filters: UserFilters) => {
  const { data } = await httpClient.get<User[]>(usersUrls.root, {
    params: filters,
  })
  return data
}

const getUser = async (id: string) => {
  const { data } = await httpClient.get<User>(usersUrls.user(id))
  return data
}

const getUserCount = async (filters: UserFilters) => {
  const { data } = await httpClient.get<number>(usersUrls.usersCount, {
    params: filters,
  })

  return data
}

const getUserRoles = async (id: string) => {
  const { data } = await httpClient.get<string[]>(usersUrls.userRoles, {
    params: { companyId: id },
  })
  return data
}

const createUser = async (props: Partial<User>) => {
  const { data } = await httpClient.post<User>(usersUrls.root, props)
  return data
}

const updateUser = async ({ id, ...props }: Partial<User>) => {
  const { data } = await httpClient.put<User>(usersUrls.user(id!), props)
  return data
}

const deleteUser = async (id: string) => {
  await httpClient.delete<User>(usersUrls.user(id))
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  getUserCount,
  getUserRoles,
}
